.c-landscape {
    display: none;
}

@media screen and (orientation:landscape) and (min-height: 319px) and (max-height: 660px) {
    .c-landscape {
        display: block;
    }

    .c-portrait {
        display: none;
    }
}