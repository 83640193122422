@import url('https://use.typekit.net/oao5ncl.css');


body {
    margin: 0;
    font-family: 'greycliff-cf', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.scroll {
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    scroll-snap-type: y proximity;
}

.scroll>div {
    scroll-snap-align: start;
}


.caption {
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    margin: 80px;
    color: white;
    font-size: 8em;
    font-weight: 100;
    line-height: 1em;
    font-variant-numeric: tabular-nums;
    -webkit-font-smoothing: auto;
}

.dot {
    pointer-events: none;
    position: sticky;
    top: 0;
    display: inline-block;
    max-width: 600px;
    padding: 80px;
    color: #a0a0a0;
    line-height: 1.6em;
    font-size: 15px;
    letter-spacing: 1.5px;
}

.dot>h1 {
    -webkit-font-smoothing: auto;
    pointer-events: none;
    color: white;
    font-size: 5em;
    font-weight: 100;
    line-height: 1em;
    margin: 0;
    margin-bottom: 0.25em;
}

@media only screen and (max-width: 600px) {
    .vheight-fix {
        height: unset;
        min-height: 100vh;
        min-height: fill-available;
        min-height: -webkit-fill-available;
    }
}

@media only screen and (max-width: 1000px) {
    .caption {
        font-size: 4em;
    }
}

@media only screen and (max-width: 800px) {
    .caption {
        font-size: 3em;
    }

    .dot>h1 {
        font-size: 3em;
    }
}

@media only screen and (max-width: 700px) {
    .caption {
        font-size: 2em;
    }

    .dot>h1 {
        font-size: 3em;
    }
}

@media only screen and (max-width: 600px) {
    .caption {
        font-size: 1em;
    }

    .dot>h1 {
        font-size: 3em;
    }
}